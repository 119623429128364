import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash-es'

// UTILS
import { UtilsHTML } from 'utils/html'
import I18n from 'i18n/i18n'
// COMPONENTS
import CountdownRemaining from '../../common/CountdownRemaining'
// CONSTANTS
import { MAXIMUM_BOOKING_NOTE_LENGTH } from 'constants/bookingConstants'
import { notesActionsCreator } from 'store/toolkit/newBooking/note.reducer'
import { isEditBooking } from '../../../utils/booking/common'

// ASSETS

const mapStateToProps = state => ({
  // currentCustomer: state.currentCustomer,
  note: state.note,
  extraInfos: state.extraInfos,
  bookAgainDetails: state.bookAgainDetails
})

const mapDispatchToProps = dispatch => ({
  noteActions: bindActionCreators(notesActionsCreator, dispatch),
})

class Note extends React.Component {
  constructor(props) {
    super(props)

    this.defaultNote = ''
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const {
      extraInfos,
      bookAgainDetails,
      noteActions
    } = this.props
    let note = _.isString(extraInfos.default_note) ? extraInfos.default_note : ''
    if (isEditBooking() && !_.isEmpty(bookAgainDetails) && _.isString(bookAgainDetails.note)) {
      note = bookAgainDetails.note
    }
    this.defaultNote = note
    noteActions.updateNote(note)
  }

  componentDidUpdate(prevProps) {
    const { extraInfos, noteActions, note } = this.props
    const { extraInfos: prevExtraInfos } = prevProps
    const defaultNote = extraInfos?.default_note || ''
    const prevDefaultNote = prevExtraInfos?.default_note || ''
    if (defaultNote !== prevDefaultNote && defaultNote && !note) {
      this.defaultNote = defaultNote
      noteActions.updateNote(defaultNote)
    }
  }

  handleBlur = (value, isDisableTrackEvent) => {
    if (!isDisableTrackEvent
      && value !== this.defaultNote
      && value !== ''
    ) {
      window.Moengage.track_event('Note To Driver Used')
      this.defaultNote = value
    }
  }

  handleChange(e) {
    const { noteActions } = this.props
    noteActions.updateNote(e)
  }

  render() {
    const { note, extraInfos } = this.props
    return (
      <div>
        {UtilsHTML.renderOptionTitle(I18n.t('webapp.new_booking.step_2.notes_block_title'), false)}
        <div className="Note block">
          <div className="ml10 mr10 mar20">
            <CountdownRemaining
              classTextarea="default-font"
              maxLength={MAXIMUM_BOOKING_NOTE_LENGTH}
              remainPercentToShow={extraInfos.remaining_characters_in_note_fields}
              value={note}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              placeholder=" "
            />
          </div>
        </div>
      </div>
    )
  }
}

Note.propTypes = {
  note: PropTypes.string.isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  bookAgainDetails: PropTypes.shape({}).isRequired,
  noteActions: PropTypes.instanceOf(Object).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Note)
