const StorageKeys = {
  ACCESS_TOKEN: 'access_token',
  LANG_SELECT: 'en',
  LANGUAGE: 'language',
  COUNTRY_CODE: 'country_code',

  AREA_ID: 'areaId',
  AREA_REDUCER: 'area_reducer',
  INVITATION_TOKEN: 'invitation_token',
  SWITCH_ACCOUNT_ATTACHMENTS: 'switch_account_attachments',
};

export default StorageKeys;
