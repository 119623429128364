import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  ITEM_TYPE_FULL_DAY_ETA_TITLE,
  ITEM_TYPE_FULL_DAY_ETA_CONTENT,
  ITEM_TYPE_NON_FULL_DAY_ETA_TITLE,
  ITEM_TYPE_NON_FULL_DAY_ETA_CONTENT,
  POPUP_ID_TRANSIT_TIME_EST,
} from 'constants/common/popupConstants'

class EstimatedTransitTimePopup extends React.PureComponent {
  constructor() {
    super()

    this.renderDynamicContent = this.renderDynamicContent.bind(this)
    this.renderPopupItems = this.renderPopupItems.bind(this)
  }

  /* eslint-disable class-methods-use-this, react/no-danger  */
  renderPopupItem(item, index) {
    const contentLinkUrl = decodeURIComponent(item.link_url)
    const contentLinkText = item.link_text
    switch (item.item_type) {
      case ITEM_TYPE_FULL_DAY_ETA_TITLE:
      case ITEM_TYPE_NON_FULL_DAY_ETA_TITLE:
        return (
          <div key={index}>
            <h5 className="eta-popup-item Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
          </div>
        )
      case ITEM_TYPE_FULL_DAY_ETA_CONTENT:
      case ITEM_TYPE_NON_FULL_DAY_ETA_CONTENT:
        return (
          <div key={index}>
            {
              (index > 1) && <div className="Normal-Booking-Devide" />
            }
            {
              contentLinkUrl
                ? (
                  <p className="normal-booking-content-link center">
                    <div
                      alt={contentLinkText}
                      className="none-link-text"
                      href={contentLinkUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      dangerouslySetInnerHTML={{ __html: item.formated_content }}
                    />
                  </p>
                )
                : (<p className="normal-booking-content-link center" dangerouslySetInnerHTML={{ __html: item.formated_content }} />)
            }
          </div>
        )
      default:
        return (
          <span key={index} />
        )
    }
  }
  /* eslint-enable class-methods-use-this, react/no-danger  */

  renderPopupItems(items) {
    return items.map((item, index) => this.renderPopupItem(item, index))
  }

  renderDynamicContent() {
    const { popupData } = this.props
    const normalItemTypes = [
      ITEM_TYPE_NON_FULL_DAY_ETA_TITLE,
      ITEM_TYPE_NON_FULL_DAY_ETA_CONTENT,
      ITEM_TYPE_FULL_DAY_ETA_TITLE,
      ITEM_TYPE_FULL_DAY_ETA_CONTENT,
    ]
    const normalItems = _.filter(
      popupData.popup_items,
      item => _.includes(normalItemTypes, item.item_type)
    )

    return (
      <div>
        {this.renderPopupItems(normalItems)}
      </div>
    )
  }

  renderPopup() {
    const {
      popupIdPrefix,
      popupData,
    } = this.props

    // Modal-No-action indicate this modal will be closed when user click on back-cover
    const modalClassName = 'vertical-scroll Modal Modal-No-action'
    const iconURL = decodeURIComponent(popupData.icon)

    return (
      <div className={modalClassName} id={`booking-${popupIdPrefix}-${POPUP_ID_TRANSIT_TIME_EST}`}>
        <div className="Normal-Booking">
          <div className="Box">
            <div className="Box-Icon">
              <div className="Logo">
                {
                  iconURL && (
                    <img src={iconURL} alt="deliveree" />
                  )
                }
              </div>
            </div>
            <div className="Box-Content">
              <div className="Normal">
                {this.renderPopupItems(popupData.popup_items)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { popupData } = this.props
    if (!popupData) {
      return null
    }

    return (
      this.renderPopup()
    )
  }
}

EstimatedTransitTimePopup.propTypes = {
  popupData: PropTypes.shape({}),
  popupIdPrefix: PropTypes.string.isRequired,
}

EstimatedTransitTimePopup.defaultProps = {
  popupData: null
}

export default EstimatedTransitTimePopup
